<template>
  <vs-row class="pt-8" vs-w="12" vs-align="flex-start" vs-type="flex" vs-justify="center">
    <vs-col vs-md="6" vs-sm="12" class="max-w-420">
      <div class="flex flex-row justify-center gap-4">
        <div class="w-1/2 md:w-2/5" @click="selectTab(1)">
          <vs-chip :class="`w-full tabs-chip ${ tabIndex == 1 ? 'chip-active' : '' }`" color="neutral-150" size="large" variant="outlined" type="multiline">
            <CardPayIcon class="chip-icon" />
            <div class="chip-text">Card</div>
          </vs-chip>
        </div>
        <div class="w-1/2 md:w-2/5" @click="selectTab(2)">
          <vs-chip :class="`w-full tabs-chip ${ tabIndex == 2 ? 'chip-active' : '' }`" color="neutral-150" size="large" variant="outlined" type="multiline">
            <DirectDebitIcon class="chip-icon" />
            <div class="chip-text">Direct debit</div>
          </vs-chip>
        </div>
      </div>
      <vs-divider class="my-8" />
      <div class="flex flex-col gap-y-8 mb-8" v-if="tabIndex == 1">
        <div class="flex md:flex-row flex-wrap justify-center gap-x-3 gap-y-2">
          <div class="text-center" :class="cardType === 'visa' || cardType === 'default' ? '' : 'opacity-30'">
            <img :src="visaCard" alt="visa" />
            <div class="text-xs font-light leading-tight">1.1%</div>
          </div>
          <div class="text-center" :class="cardType === 'mastercard' || cardType === 'default' ? '' : 'opacity-30'">
            <img :src="masterCard" alt="mastercard" />
            <div class="text-xs font-light leading-tight">1.1%</div>
          </div>
          <div class="text-center" :class="cardType === 'amex' || cardType === 'default' ? '' : 'opacity-30'">
            <img :src="amexCard" alt="amex" />
            <div class="text-xs font-light leading-tight">1.1%</div>
          </div>
        </div>
        <div class="w-full"><vs-input label="Name on card" v-model="cardName" :success="cardNameValid" icon="done" icon-after="true" icon-size="small" @change="checkFieldValidation('cardName')" /></div>
        <div class="w-full">
          <div class="custom-input-field" :class="[!cardValid || !isCardAllowed || showCardNumberIsRequired ? 'hasError' : '', cardFocused ? 'isFocused' : '', cardSuccess ? 'isValid' : '']" >
            <label for="number_div" class="input-label">Card number</label>
            <div id="number_div" style="height: 28px"></div>
            <vs-icon class="validation-icon" icon="done" icon-after="true" icon-size="small" />
          </div>
          <span class="span-text-validation-danger" v-if="!isCardAllowed && !showCardNumberIsRequired"><span class="span-text-validation">This card type is not supported. Please try another card</span></span>
          <span class="span-text-validation-danger" v-if="showCardNumberIsRequired"><span class="span-text-validation">Card number is required</span></span>
          <span class="span-text-validation-danger" v-if="!cardValid && !showCardNumberIsRequired && isCardAllowed"><span class="span-text-validation">Invalid card number</span></span>
        </div>
        <div class="flex flex-row gap-x-6">
          <div class="w-1/2">
            <vs-input class="input-arial" :class="showExpDateIsRequired || showExpired ? 'hasError' : ''" label="Expiry date" placeholder="MM/YY" v-mask="'##/##'" v-model="expiryDate" :success="dateSuccess" icon="done" icon-after="true" icon-size="small" @keyup.native="handleExpiryCardField" @blur="handleExpiryCardField1" />
            <span class="span-text-validation-danger" v-if="showExpDateIsRequired"><span class="span-text-validation">Card expiry date is required</span></span>
            <span class="span-text-validation-danger" v-else-if="showExpired"><span class="span-text-validation">Expiry date is invalid</span></span>
          </div>
          <div class="w-1/2">
            <div class="custom-input-field" :class="[securityFocused ? 'isFocused' : '', showCvcIsRequired ? 'hasError' : '', cvvSuccess ? 'isValid' : '', isInvalidCvv ? 'hasError' : '' ]">
              <label class="input-label">
                Security code
                <popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '-55px, 2px' }}}">
                  <template slot="reference" class="top"><ToolTipIcon width="12px" height="12px" class="vs-icon" /></template>
                  <div class="popper py-2 px-4 text-center" v-if="cardType == 'amex'">
                    <p class="text-primary">The 4 digits on the front of your card.</p>
                    <div class="flex flex-row gap-x-4">
                      <div><img :src="amex1" alt="" /></div>
                    </div>
                  </div>
                  <div class="popper py-2 px-4" v-else-if="['visa', 'mastercard'].includes(cardType)">
                    <p class="text-primary">The 3 digits on the back of your card.</p>
                    <div class="flex flex-row gap-x-4">
                      <div><img :src="general1" alt="" /></div>
                    </div>
                  </div>
                  <div class="popper py-2 px-4 text-center" v-else>
                    <p>For American Express (left), the 4 digits on the front of your card.</p>
                    <p>For all other cards (right), the 3 digits on the back of your card.</p>
                    <div class="flex flex-row pt-2 gap-x-4">
                      <div><img :src="amex" alt="" /></div>
                      <div><img :src="general" alt="" /></div>
                    </div>
                  </div>
                </popper>
              </label>
              <div id="cvv_div" style="height: 28px"></div>
              <vs-icon class="validation-icon" icon="done" icon-after="true" icon-size="small" />
            </div>
            <span class="span-text-validation-danger" v-if="showCvcIsRequired"><span class="span-text-validation">Security code is required</span></span>
            <span class="span-text-validation-danger" v-else-if="isInvalidCvv"><span class="span-text-validation">Security code is invalid</span></span>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-y-8 mb-8" v-if="tabIndex == 2">
        <div class="w-full"><vs-input label="Account name" v-model="accountName" :success="accountNameValid" icon="done" icon-after="true" icon-size="small" @change="checkFieldValidation('accountName')" /></div>
        <div class="flex flex-row gap-x-6">
          <div class="w-2/5">
            <vs-input
              label="BSB"
              :class="!bsbError ? 'hasError' : ''"
              placeholder="000 - 000"
              v-mask="'###-###'"
              v-model="bsb"
              :success="bsbSuccess"
              icon="done"
              icon-after="true"
              icon-size="small"
              @blur="checkBsbValidity"
              @keyup="handleBsb"
            />
            <span class="span-text-validation-danger" v-if="!showBsbValid"><span class="span-text-validation">Invalid BSB</span></span>
          </div>
          <div class="w-3/5">
            <vs-input type="number" name="accountNumber" label="Account number" v-model="accountNumber" :success="accountNumberValid" icon="done" icon-after="true" icon-size="small" v-validate="'required'" data-vv-validate-on="blur" data-vv-as="account number" @change="checkFieldValidation('accountNumber')" />
            <span class="span-text-validation-danger" v-if="errors.has('accountNumber')"><span class="span-text-validation">{{ errors.first("accountNumber") }}</span></span>
          </div>
        </div>
      </div>
      <template v-if="showButton">
        <div class="flex flex-row items-start mb-10">
          <vs-checkbox v-model="acceptTerms" name="acceptTerms">&nbsp;</vs-checkbox>
          <p class="ancillary-regular cursor-pointer" @click="acceptTermsNow">I authorise the debiting of the above payment/s to the nominated payment method, and agree to the Terms & Conditions and Privacy Policy.</p>
        </div>
        <div>
          <vs-button :disabled="!acceptTerms" color="primary" class="w-full mb-4" size="large" @click="handleAdd">{{ buttonText }}</vs-button>
          <vs-button type="flat" @click="cancel" size="medium" class="block mx-auto">Cancel</vs-button>
        </div>
      </template>
    </vs-col>
  </vs-row>
</template>

<script>
import moment from "moment";
import { mask } from 'vue-the-mask';
import Popper from 'vue-popperjs';
import CardPayIcon from "./design-sys-components/icons/CardPayIcon";
import DirectDebitIcon from "./design-sys-components/icons/DirectDebitIcon";
import ToolTipIcon from "./design-sys-components/icons/ToolTipIcon";

const visaCard = require("@/assets/images/cards/visa.svg");
const masterCard = require("@/assets/images/cards/mastercard.svg");
const amexCard = require("@/assets/images/cards/amex.svg");
const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
const unionpayCard = require("@/assets/images/cards/unionpay.svg");
const directDebitCard = require("@/assets/images/cards/directdebit.svg");
// Tooltip images
const amex = require("@/assets/images/cards/amex.png");
const amex1 = require("@/assets/images/cards/amex-1.png");
const general = require("@/assets/images/cards/general.png");
const general1 = require("@/assets/images/cards/general-1.png");

export default {
  data() {
    return {
      cardName: '',
      expiryDate: '',
      accountName: '',
      bsb: '',
      accountNumber: '',
      visaCard: visaCard,
      masterCard: masterCard,
      amexCard: amexCard,
      dinersClubCard: dinersClubCard,
      unionpayCard: unionpayCard,
      directDebitCard: directDebitCard,
      amex: amex,
      amex1: amex1,
      general: general,
      general1: general1,
      tabIndex: 1,
      till_public_key: "ZVbc1fL9SYAiHe3hyZvg",
      paymentJSObject: undefined,
      card_status: {},
      card_options: [
        { card: { slug: "visa" } },
        { card: { slug: "mastercard" } },
        { card: { slug: "amex" } },
      ],
      isCardAllowed: true,
      showCardNumberIsRequired: false,
      showCvcIsRequired: false,
      dateSuccess: false,
      dateError: true,
      showExpDateIsRequired: false,
      showExpired: false,
      showBsbValid: true,
      bsbSuccess: false,
      bsbError: true,
      cardType: "default",
      cardValid: true,
      cardValidity: true,
      cardFocused: false,
      securityFocused: false,
      cardSuccess: false,
      cvvSuccess: false,
      cardNameValid: false,
      accountNameValid: false,
      accountNumberValid: false,
      isInvalidCvv: false,
      acceptTerms: false
    }
  },

  directives: {
    mask
  },

  components: {
    Popper,
    CardPayIcon,
    DirectDebitIcon,
    ToolTipIcon,
  },

  props: {
    buttonText:{
      type: String,
      default: "Add"
    },
    showButton: {
      type: Boolean,
      default: false
    },
  },

  methods: {
    selectTab(index) {
      this.tabIndex = index;

      if (this.tabIndex == 1) {
        this.paymentJSObject = undefined;
        this.initializePaymentObject();
        this.cardSuccess = false;
        this.cvvSuccess = false;
        this.cardNameValid = false;
        this.isInvalidCvv = false;
        this.dateSuccess = false;
        this.cardName = '';
        this.expiryDate = '';
        this.cardType = 'default';
      } else {
        this.bsbSuccess = false;
        this.accountNameValid = false;
        this.accountNumberValid = false;
        this.accountName = '';
        this.bsb = '';
        this.accountNumber = '';
      }
    },
    async initializePaymentObject() {
      try {
        if (this.paymentJSObject != undefined) {
          delete this.paymentJSObject;
          return;
        } else {
          this.paymentJSObject = new PaymentJs();
          await this.paymentJSObject.init(this.till_public_key, "number_div", "cvv_div", this.setPaymentStyle);
        }
      } catch(e) {
        return;
      }
    },

    setPaymentStyle(payment) {
      payment.setNumberStyle({
        "width": "100%",
        "border": "none",
        "box-sizing": "border-box",
        "height": "28px",
        "outline": 0,
        "font-family": "Arial",
        "font-size": "14px",
        "color": "#0D0F11",
        "padding": "0 8px",
        "background": "#FBFBFB",
      });
      payment.setCvvStyle({
        "width": "100%",
        "border": "none",
        "outline": 0,
        "font-family": "Arial",
        "box-sizing": "border-box",
        "height": "28px",
        "font-size": "14px",
        "color": "#0D0F11",
        "padding": "0 8px",
        "background": "#FBFBFB",
      });
      payment.numberOn("input", this.checkCardValidity);
      payment.setNumberPlaceholder("0000 0000 0000 0000");
      payment.numberOn('focus', this.handleCardFocus);
      payment.cvvOn('focus', this.handleCvvFocus);
      payment.numberOn('blur', this.handleCardBlur);
      payment.cvvOn('blur', this.checkCvvValidity);
    },

    checkCardValidity(data) {
      this.card_status = data;
      this.cardValid = true;
      if (this.card_status.numberLength == 0) {
        this.showCardNumberIsRequired = true;
        this.cardType = "";
      }

      if (this.card_status.numberLength > 0) {
        this.showCardNumberIsRequired = false;
      }

      let checkCardAllowedStatus = this.card_options.find((card_option) => {
        return card_option.card.slug === data.cardType;
      });

      if (data.cardType) {
        this.cardType = data.cardType;
        this.cardValidity = data.validNumber;
        this.cardSuccess = data.validNumber && checkCardAllowedStatus;

        if (!checkCardAllowedStatus) {
          this.isCardAllowed = false;
        } else {
          this.isCardAllowed = true;
        }
      } else {
        this.cardSuccess = false;
      }
    },

    handleCardBlur() {
      this.cardValid = this.cardValidity;
      this.cardFocused = false;
    },

    handleCardFocus() {
      this.cardFocused = true;
    },

    handleCvvBlur() {
      this.securityFocused = false;
    },

    handleCvvFocus() {
      this.securityFocused = true;
    },

    checkCvvValidity(data) {
      this.securityFocused = false;
      this.card_status = data;
      this.cvvSuccess = data.validCvv;

      if (this.card_status.cvvLength == 0) {
        this.showCvcIsRequired = true;
      }
      if (this.card_status.cvvLength > 0) {
        this.showCvcIsRequired = false;
      }
      this.isInvalidCvv = !data.validCvv;
    },

    handleExpiryCardField(event) {
      const value = event.target.value;
      this.checkExpiryDate(value);

      if (value.length < 5) {
        this.dateSuccess = false;
        return;
      }

      this.showExpired = this.checkExpiryDate(value);
      this.dateSuccess = (value.length == 5 && !this.showExpired);
    },

    handleExpiryCardField1(event) {
      this.showExpDateIsRequired = (event.target.value.length === 0);

      if (!this.showExpDateIsRequired) {
        if (event.target.value.length < 5) {
          this.showExpired = true;
          this.dateSuccess = false;
        } else {
          this.dataSuccess = this.checkExpiryDate(event.target.value);
        }
      }
    },

    checkExpiryDate(event) {
      const expiryDate = this.expiryDate.split("/");
      const firstValue = expiryDate[0].charAt(0);

      if (firstValue >= 2) {
        this.expiryDate = "0" + firstValue;
      }

      if (event.key == '/' && this.expiryDate.length == 1) {
        this.expiryDate = "0" + firstValue + "/";
      }

      if (expiryDate.length == 2) {
        const fullDate = `20${expiryDate[1]}-${expiryDate[0]}`;

        if (moment(fullDate).isSame(moment(new Date()).format("YYYY-MM"))) {
          return false;
        }

        return !moment(fullDate).isAfter(new Date());
      }

      return false;
    },

    handleBsb() {
      const bsbData = this.bsb.split("-");

      if (bsbData.length == 2 && bsbData[1].length == 3) {
        this.showBsbValid = true;
        this.bsbSuccess = this.showBsbValid;
      }
    },

    checkBsbValidity() {
      const bsbData = this.bsb.split("-");
      this.showBsbValid = (bsbData[0].length == 3 && bsbData[1].length == 3);
      this.bsbSuccess = this.showBsbValid;
      this.bsbError = this.showBsbValid;
    },

    checkFieldValidation(field) {
      if (field == 'cardName') {
        this.cardNameValid = this.cardName.length > 0;
      } else if (field == 'accountName') {
        this.accountNameValid = this.accountName.length > 0;
      } else if (field == 'accountNumber') {
        this.accountNumberValid = this.accountNumber.length > 0 && !this.errors.has('accountNumber');
      }
    },

    acceptTermsNow() {
      this.acceptTerms = !this.acceptTerms;
    },

    cancel() {
      this.paymentJSObject = undefined;
      this.cardSuccess = false;
      this.cvvSuccess = false;
      this.cardNameValid = false;
      this.isInvalidCvv = false;
      this.dateSuccess = false;
      this.cardName = '';
      this.expiryDate = '';
      this.cardType = 'default';
      this.bsbSuccess = false;
      this.accountNameValid = false;
      this.accountNumberValid = false;
      this.accountName = '';
      this.bsb = '';
      this.accountNumber = '';
      console.log("cancelled");
      this.$emit("cancel");
    },

    handleAdd() {
      this.$emit("handleAdd");
    }
  },

  mounted() {
    this.initializePaymentObject();
  },

  watch: {
    bsb: function(val) {
      if (val.length == 0) {
        this.showBsbValid = true;
      }
    }
  }
}
</script>
