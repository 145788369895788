<template class="mt-5">
<div>
  <p class="text-sm mb-2" v-if="label"
  :class="[
  {'text-body-dark': !isFocus && !errors.has(`${name}`) && !hasError && !disabled},
  {'text-interaction-error': errors.has(`${name}`) || hasError},
  {'text-interaction-primary': isFocus && (!errors.has(`${name}`) && !hasError)},
  {'text-neutral-500 disabled': disabled}]">{{ label }}</p>

  <quill-editor v-model="model" :readOnly="true" ref="quillEditor" @blur="tellBlurOccurred" :options="editorOption" @ready="onEditorReady($event)" @change="onEditorChange($event)"
  v-validate="validate" :name="name" :id="name" @focus="onEditorFocus($event)" :class="[
  {'isError': errors.has(`${name}`) || hasError},
  {'isActive': isFocus && (!errors.has(`${name}`) && !hasError)},
  {'disabled': disabled}]"> </quill-editor>

  <div id="counter" class="flex items-center ancillary mt-2 text-body">
    <span v-show="errors.has(`${name}`) || hasError" class="text-interaction-error">{{ errorMessage || errors.first(`${name}`) }}</span>
    <span class="ml-auto" :class="[
    {'text-interaction-error': errors.has(`${name}`) || hasError},
    {'text-interaction-primary': isFocus && (!errors.has(`${name}`) && !hasError)},
    {'text-neutral-500 disabled': disabled}]">{{ textLength }}/{{ counter }}</span>
  </div>
</div>
</template>
<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  data() {
    return {
      isFocus: false,
      textLength: 0,
    }
  },
  components: { quillEditor },
  props: {
    value: {
      type: String,
      default: ''
    },
    counter: {
      type: String,
      default: '20'
    },
    editorOption: {
      type: Object,
      default: () => ({
        modules: {
          toolbar: [
            [
              "bold",
              "italic",
              "underline",
              "link",
            ],
          ],
        }
      })
    },
    name: {
      type: String,
      default: 'text'
    },
    validate: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    editor() {
      return this.$refs.quillEditor.quill;
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    insertTextAtCursor(text) {
      let selection = this.editor.getSelection(true);
      this.editor.insertText(selection.index, text);
    },

    tellBlurOccurred() {
      this.isFocus = false;
      this.$emit("onQuillBlur");
    },

    calculate(quill) {
      let text = quill.getText();
      this.textLength = text.length-1;
      if (quill.getLength() > parseInt(this.counter)) {
        quill.deleteText(parseInt(this.counter), quill.getLength());
      }
    },

    onEditorReady(quill) {
      if (this.disabled) {
        this.quill.setDisabledState(true);
      }
      this.calculate(quill);
    },

    onEditorChange({ quill }) {
      this.calculate(quill);
    },

    onEditorFocus(quill) {
      this.isFocus = true;
    },

    onMouseover() {
      if (this.disabled) {
        this.editor.enable(false);
      }
    },

    onMouseleave() {
      if (this.disabled) {
        this.editor.enable(true);
      }
    }
  },
};
</script>


